<template>
  <v-card height="100%">
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-cash</v-icon>
      Grupos de Renda
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <Bar
        :chartData="graficoData"
        :chartOptions="chartOptions"
        :styles="styles"
      />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhuma Pessoa Cadastrada
    </v-card-text>
  </v-card>
</template>

<script>
import { gruposRenda } from "@/api/admin/graficos/usuarios.js";

export default {
  name: "BoxTotalPosts",

  components: {
    Bar: () => import("@/views/home/graficos/Bar.vue"),
  },

  data() {
    return {
      graficoData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
      },
      styles: {
        width: "100%",
      },
      loading: false,
    };
  },

  methods: {
    async getGruposRenda() {
      this.loading = true;
      try {
        const response = await gruposRenda();
        const colors = [
          "#FFF9C4", // Yellow 100
          "#FFF59D", // Yellow 200
          "#FFF176", // Yellow 300
          "#FFEE58", // Yellow 400
          "#FFEB3B", // Yellow 500
          "#FDD835", // Yellow 600
        ];
        this.graficoData = {
          labels: ["Renda Anual"],
          datasets: response.map((item, index) => {
            return {
              label: item.faixa_renda,
              data: [item.renda_anual_total],
              backgroundColor: colors[index],
            };
          }),
					
        };

        this.chartOptions = {
          responsive: true,
          plugins: {
            dataLabels: {
              display: true,
              color: "black",
            },
          },
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    reloadGet() {
      this.graficoData = {
        labels: [],
        datasets: [],
      };
      this.getGruposRenda();
    },
  },

  mounted() {
    this.getGruposRenda();
  },
};
</script>
<style></style>
